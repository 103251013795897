<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Chicks
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form method="post" @submit="addEggStock">
				<b-card>
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Hatch No <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Hatch No ">
										<b-form-input
											v-model="FormData.hatch_no"
											placeholder="Hatch No "
											type="text"
											name="hatch_no"
											maxlength="50"
										:max="maxDate" 
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Hatch-Date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Hatch-Date" rules="required">
									<b-form-datepicker
										name="Hatch-Date"
										v-model="FormData.hatch_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Source<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Source" rules="required">
										<v-select
											v-model="FormData.source_type"
											:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
											:options="sourceList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Production Type <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Production Type " rules="required">
										<v-select
											v-model="FormData.production_type"
											:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
											:options="productionTypes"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Species <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Species " rules="required">
										<v-select
											v-model="FormData.species"
											:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
											:options="speciesList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Strain <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Strain " rules="required">
										<v-select
											v-model="FormData.strain"
											:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
											:options="strainList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Purchase date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Purchase date" rules="required">
									<b-form-datepicker
										v-model="FormData.purchase_date"
										name="Purchase date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Quantity Received <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name=" Quantity Received "
										rules="required|regex:^([0-9]+)$"
									>
										<b-form-input
											v-model="FormData.quantity"
											placeholder="Quantity Received "
											type="text"
											name="flock_age"
											maxlength="10"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Dead and Rejected Birds <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name=" Dead and Rejected Birds "
										rules="required|regex:^([0-9]+)$"
									>
										<b-form-input
											v-model="FormData.dead_chick"
											placeholder=" Dead and Rejected Birds "
											type="text"
											name="dead_chick"
											maxlength="10"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Rate Per Bird <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name=" Rate Per Bird "
										:rules="{
											required: true,
											regex: RegExp(/^\d+(\.\d{0,2})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.chick_rate"
											placeholder=" Rate Per Bird "
											type="text"
											name="price_per_egg"
											maxlength="10"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>

						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as Vue from "vue";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { required, regex } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			regex,
			required,
			speciesList: [],
			strainList: [],
			productionTypes: [],
			sourceList: [],
			FormData: {
				hatch_no: null,
				hatch_date: null,
				source_type: null,
				production_type: null,
				species: null,
				strain: null,
				purchase_date: null,
				quantity: null,
				dead_chick: null,
				chick_rate: null,
			},
			 maxDate: new Date().toISOString().split("T")[0],
		};
	},
	created: function () {
		this.getSourceTypes();
		this.getProductionTypes();
		this.getSpeciesTypes();
		this.getStrainTypes();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getSourceTypes() {
			axiosIns
				.get(`web/get-all-sources`)
				.then((response) => {
					this.sourceList = response.data.source_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getProductionTypes() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getSpeciesTypes() {
			axiosIns
				.get(`web/species`)
				.then((response) => {
					this.speciesList = response.data.species;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getStrainTypes() {
			axiosIns
				.get(`web/strains`)
				.then((response) => {
					this.strainList = response.data.strain;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addEggStock(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("hatch_no", this.FormData.hatch_no);
					data.append("hatch_date", this.FormData.hatch_date);
					data.append("purchase_date", this.FormData.purchase_date);
					data.append("production_type_id", this.FormData.production_type);
					data.append("source_id", this.FormData.source_type);
					data.append("quantity", this.FormData.quantity);
					data.append("dead_chick", this.FormData.dead_chick);
					data.append("species_id", this.FormData.species);
					data.append("strain_id", this.FormData.strain);
					data.append("chick_rate", this.FormData.chick_rate);
					// data.append("farm_id", this.$route.params.farmId);
					axiosIns
						.post(`web/purchase-chick`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								name: `apps-company-purchase`,
							});
						})
						.catch((error) => {
							error.response;
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
